

import { Login } from '../models/login'
import { computed} from 'vue'
import { ref } from 'vue'
import AuthRepository from '../services/AuthRepository'
import { useStore } from 'vuex'
import { useToast } from 'primevue/usetoast'
import { useRouter } from 'vue-router'
import router from "@/router";

export default {

  setup (props) {
    const router = useRouter();
    const login = ref({} as Login);
    const store = useStore();
    const toast = useToast();
    const userName = ref("");
    const password = ref("");

    const valid = computed(() => password.value && userName.value);

    // if(!(store.state.permission == "admin")){
    //   router.push({
    //     name: 'home'
    //   });
    // }

      console.log('############################## loginpage 11111');
    const doLogin = () => {
      console.log('############################## loginpage');
      var md5 = require("md5");
      login.value.username = userName.value;
      login.value.password = md5(password.value);
      console.log('############################## loginpage login: ' + JSON.stringify(login));
      AuthRepository.auth(login.value)
          .then((response) => {
            store.dispatch('setToken', response.data.token)
            console.log('############################## loginpage success');
            toast.add({
              severity: 'success',
              summary: 'Đăng nhập',
              detail: 'Đăng nhập thành công vào hệ thống',
              life: 1000
            });
            AuthRepository.userPermission(login.value)
                .then((response) => {
                  store.dispatch('setPermission', response.data.permission);
                  console.log('############################## loginpage permission');
                })
                .catch(() => {
                  toast.add({
                    severity: 'error',
                    summary: 'Đăng nhập',
                    detail: 'Đăng nhập thất bại. Vui lòng kiểm tra lại thông tin đăng nhập',
                    life: 1000
                  })
                })
            router.push({
              name: 'home'
            });
          })
          .catch(() => {
            toast.add({
              severity: 'error',
              summary: 'Đăng nhập',
              detail: 'Đăng nhập thất bại. Vui lòng kiểm tra lại thông tin đăng nhập',
              life: 1000
            })
          })
    }

    return {
      login,
      doLogin,
      valid,
      password,
      userName,
    }
  }
}

